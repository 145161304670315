import moment from "moment";
import React, { Fragment } from "react";

// Icons
import { AiOutlinePhone } from "@react-icons/all-files/ai/AiOutlinePhone";

// Components
import { Heading, HeroImage, HorizontalDividerCard } from "@components";

// Images
import ServicePlaceholder from "@images/placeholder.png";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const ServiceDetails = ({ pageContext }) => {
    // const { name, phone, image, description, openingHours } = pageContext;
    const openingHours = [
        {
            id: 1,
            name: "Monday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 19, 0),
        },
        {
            id: 2,
            name: "Tuesday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 19, 0),
        },
        {
            id: 3,
            name: "Wednesday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 19, 0),
        },
        {
            id: 4,
            name: "Thursday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 19, 0),
        },
        {
            id: 5,
            name: "Friday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 19, 0),
        },
        {
            id: 6,
            name: "Saturday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 16, 0),
        },
    ];

    const { placeholder } = useStaticQuery(
        graphql`
            query {
                placeholder: file(relativePath: { eq: "pages/doctors.jpeg" }) {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    return (
        <>
            <HeroImage
                title={"Doctors"}
                image={placeholder?.childImageSharp?.fluid}
            />

            <div className="max-w-1280 mx-auto py-5 px-3 xl:px-0 flex flex-col md:flex-row justify-between gap-x-8">
                <div className="pt-3 w-full md:w-3/5">
                    <p className="mb-4">
                        Here at Broad Oak Medical, Dental and Specialist Centre
                        our GP’s offer a range of medical services so we can
                        provide quality, holistic and comprehensive healthcare.
                    </p>

                    <p className="mb-2">
                        Apart from our general medical consultations, our GP’s
                        also offer services such as:
                    </p>

                    <ul className="list-disc pl-3">
                        <li>Iron infusions</li>
                        <li>Ear syringing and ear suction</li>
                        <li>Wedge resection</li>
                        <li>Implants removal/insertion</li>
                        <li>Biopsies</li>
                        <li>ECG’s</li>
                        <li>Wound care</li>
                        <li>Work Cover consultations</li>
                        <li>
                            Cosmetic injections; anti-wrinkle injections,
                            fillers and PRP for hair loss treatment.
                        </li>
                    </ul>

                    <p className="mt-8">
                        If you have any enquires or wish to make an appointment
                        for any of the above services, please contact our
                        friendly reception on 5995 1000.
                    </p>

                    <div className="w-full mt-4">
                        <StaticImage
                            src={"../../images/pages/doctors.jpeg"}
                            alt={"Doctors Image"}
                            className="w-full h-52 object-cover rounded-xl shadow"
                        />
                    </div>
                </div>
                <div className="w-full md:w-2/5 pt-3">
                    <Heading size={"xl"}>Opening hour</Heading>
                    {openingHours && openingHours.length > 0 ? (
                        openingHours?.map(
                            ({ id, name, opening, closing }, i) => (
                                <Fragment key={id}>
                                    <div className="w-full flex justify-between items-center py-1">
                                        <h4 className="text-black text-sm  font-normal mb-0">
                                            {name}
                                        </h4>
                                        <p className="text-black text-sm  font-semibold mb-0">
                                            {moment(opening, "hh:mm:ss").format(
                                                "h:mm a"
                                            )}{" "}
                                            -{" "}
                                            {moment(closing, "hh:mm:ss").format(
                                                "h:mm a"
                                            )}
                                        </p>
                                    </div>
                                    {i !== openingHours.length - 1 && (
                                        <HorizontalDividerCard
                                            colors={[
                                                "#FFFFFF00",
                                                "#7B61FF",
                                                "#FFFFFF00",
                                            ]}
                                        />
                                    )}
                                </Fragment>
                            )
                        )
                    ) : (
                        <div className="mt-10">
                            <AiOutlinePhone className="text-gray77 text-3xl mx-auto" />
                            <p className="text-base text-gray77 text-center  mt-2.5">
                                Please call for appointments
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ServiceDetails;
